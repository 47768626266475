import { t } from "lib/i18n";
import { getParent, ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";
import _ from "lodash";

export const GoodSchema: ValidationSchema = {
    name: ruleset.required,
    barcode: ruleset.barcodeWeight,
    price: ruleset.combineIf(
        (_value, values) => _.isEmpty(values.modifications),
        [ruleset.decimal2, ruleset.gte(0), ruleset.required],
    ),
    low_stock: ruleset.required,
    modifications: [
        {
            name: value => (value.trim() === "" ? t("required") : false),
        },
    ],
    meta: {
        availability: {
            schedules: {
                itemAvailability: [
                    {
                        days: ruleset.combineIf(
                            (_value, values) => !!values.meta.availability.itemAvailability,
                            [ruleset.minLength(1)],
                        ),
                        hours: [
                            {
                                from: ruleset.combineIf(
                                    (_value, values) => !!values.meta.availability.itemAvailability,
                                    [
                                        (value, values, parent, meta) => {
                                            const currentIndex = meta.currentIndex;
                                            if (!currentIndex) return;

                                            const prev = getParent(parent).hours[currentIndex - 1];

                                            return ruleset.gtTime(
                                                prev.to,
                                                t("start_date_can_not_be_great_before_end_date"),
                                            )(value, values, parent) as string;
                                        },
                                    ],
                                ),
                                to: ruleset.combineIf(
                                    (_value, values) => !!values.meta.availability.itemAvailability,
                                    [ruleset.gtTime("from", t("start_date_can_not_be_great_end_date"), true)],
                                ),
                            },
                        ],
                    },
                ],
                itemVisibility: [
                    {
                        days: ruleset.combineIf(
                            (_value, values) => !!values.meta.availability.itemVisibility,
                            [ruleset.minLength(1)],
                        ),
                        hours: [
                            {
                                from: ruleset.combineIf(
                                    (_value, values) => !!values.meta.availability.itemVisibility,
                                    [
                                        (value, values, parent, meta) => {
                                            const currentIndex = meta.currentIndex;
                                            if (!currentIndex) return;

                                            const prev = getParent(parent).hours[currentIndex - 1];

                                            return ruleset.gtTime(
                                                prev.to,
                                                t("start_date_can_not_be_great_before_end_date"),
                                            )(value, values, parent) as string;
                                        },
                                    ],
                                ),
                                to: ruleset.combineIf(
                                    (_value, values) => !!values.meta.availability.itemVisibility,
                                    [ruleset.gtTime("from", t("start_date_can_not_be_great_end_date"), true)],
                                ),
                            },
                        ],
                    },
                ],
            },
        },
    },
};
