import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import CheckboxFormGroup from "components/Form/CheckboxFormGroup";
import ColorPicker from "components/Form/ColorPicker";
import SelectFormGroup from "components/Form/SelectFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import { useData } from "hooks/useData";
import CFormGroup from "components/Form/CFormGroup";
import GoodsSettingsByVenue from "components/GoodsSettingsByVenue";
import { ruleset } from "lib/validation-rules";
import _ from "lodash";
import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, CheckPicker, IconButton } from "rsuite";
import { iCheckBoxChecked, iClose, iMore } from "assets/icons/Icons";
import { CategoryTypeEnum } from "config/constants";
import MenuCategorySelectFormGroup from "components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import CIcon from "components/CIcon";
import { useDrawer } from "components/Drawers/useDrawer";
import UploaderFormGroup from "components/Form/FileUploadFormGroup";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import CreateCategoryModal from "pages/menu/categories/CreateCategoryModal";
import { useAppSelector } from "hooks/useRedux";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import { translateUnit } from "lib/utils";
import { SelectGovCodeDrawer } from "components/Drawers/SelectGovCodeDrawer";
import { SelectGovPackageCodeDrawer } from "components/Drawers/SelectGovPackageCodeDrawer";
import { useSetting } from "hooks/useSetting";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { useIntegrationByName } from "providers/PackageProvider";
import { useMenuCategories } from "hooks/data-hooks/useMenuCategories";
import { CTreePickerFormGroup } from "components/Form/CTreePickerFormGroup";

const EditGeneralTab: FC = () => {
    const params = useParams<any>();
    const returnsMaxAge = useSetting("returns_max_age")?.value;
    const brandData = useAppSelector(state => state.app.brandData);
    const form = useCFormContext();
    const stations = useData<IStation[]>("station", { params: { limit: 999 } });
    const modal = useDrawer();
    const auth = useAuth();
    const taxes = useData<IStorage[]>("finance/taxes", {}, auth.hasAbility(["tax_view"]));
    const selectGovCodeDrawer = useDrawer();
    const selectGovPackageCodeDrawer = useDrawer();
    const categories = useMenuCategories(true, [CategoryTypeEnum.PRODUCT]);
    const hasExciseIntegration = !!useIntegrationByName("excise");

    const hasModifications = form.watch("has_modifications");
    const meta = form.watch("meta");
    const soldByWeight = form.watch("sold_by_weight");
    const isInventoryBehaviorChangeable = form.watch("is_inventory_behavior_changeable") ?? true;

    useEffect(() => {
        if (hasModifications) {
            form.setValue("barcode", "");
        }
    }, [hasModifications]);

    useEffect(() => {
        if (soldByWeight) {
            form.setValue("properties.hasExcise", false);
            form.setValue("meta.hasPortion", false);
        }
    }, [soldByWeight]);

    return (
        <div className="tw-p-4">
            <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />
            <TextFormGroup
                name="barcode"
                compProps={{
                    disabled: hasModifications,
                    onPressEnter: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        e.key === "Enter" && e.preventDefault();
                    },
                    maxLength: soldByWeight ? 5 : undefined,
                }}
                errorPlacement="rightEnd"
                rules={{
                    validate: {
                        barcodeWeight: ruleset.barcodeWeight,
                    },
                }}
            />

            <CTreePickerFormGroup
                label={t("select_category")}
                name="category_id"
                data={categories.tree}
                placeholder={t("select_category")}
                disabled={meta?.fromMenuConstruct}
                compProps={{
                    placement: "auto",
                    className: "tw-w-input-lg",
                    cleanable: true,
                    customFooter: () => {
                        return (
                            <div className={"tw-p-2"}>
                                <Button
                                    appearance={"subtle"}
                                    className={"tw-w-full"}
                                    test-id={"create-category_id"}
                                    onClick={() => {
                                        modal.setDataId(0);
                                        modal.setData({
                                            name: "category_id",
                                            type: CategoryTypeEnum.PRODUCT,
                                        });
                                    }}
                                >
                                    {t("create_new_by_name", { name: t("category") })}
                                </Button>
                            </div>
                        );
                    },
                }}
            />

            <SelectFormGroup
                name="station_id"
                data={stations.data}
                compProps={{ cleanable: true }}
                errorPlacement="rightEnd"
            />

            <UploaderFormGroup
                name="images[0].uuid"
                removeMainKey={true}
                label={t("image")}
                buttonStyle={{ height: 90, width: 160 }}
                fileInfoStyle={{ height: 90, width: 160 }}
                compProps={{
                    action: "media",
                    multiple: false,
                    listType: "picture",
                    name: "media_file",
                }}
            />

            <ColorPicker
                name="color"
                label={t("display")}
                className={_.isEmpty(form.getValues("images")) ? "d-block" : "d-none"}
            />

            <TextFormGroup
                className="tw-text-secondary-200 tw-mb-2"
                name="low_stock"
                type={"number"}
                errorPlacement="topEnd"
                compProps={{
                    description: (
                        <CheckboxFormGroup
                            className={"tw-text-secondary-200"}
                            controlWrapperProps={{}}
                            name={"properties.should_notify_low_stock"}
                            label={t("notification")}
                        />
                    ),
                }}
            />

            {!hasModifications && (
                <TextFormGroup
                    name="price"
                    type="money"
                    compProps={{
                        className: "tw-p-2",
                        disabled: hasModifications,
                        defaultValue: params?.id ? (form.getValues("price") ?? 0) : undefined,
                        description: t("cost_price") + " " + (form.getValues("cost_price") ?? 0),
                    }}
                    labelProps={{
                        className: "!tw-py-4 tw-font-bold ",
                    }}
                    rules={{
                        validate: {
                            required: ruleset.required,
                            decimal2: ruleset.decimal2,
                        },
                    }}
                />
            )}

            <h5 className="tw-m-0 tw-text-xl tw-mt-4">
                <TText tkey="details" />
            </h5>
            <hr className="tw-mt-1 tw-mb-4" />
            <MenuCategorySelectFormGroup
                type={CategoryTypeEnum.ACCOUNTING}
                label={t("accounting_category")}
                name={"accounting_category_id"}
                disabled={meta?.fromMenuConstruct}
                modal={modal}
                revalidate={modal.dataId}
                compProps={{ cleanable: true }}
                types={[CategoryTypeEnum.ACCOUNTING]}
            />

            <TextFormGroup
                name="max_age"
                type={"number"}
                label={t("product_return_date_range")}
                compProps={{
                    placeholder: `${t("default_count")}: ${returnsMaxAge}`,
                }}
                rules={{
                    validate: {
                        maxSettings: ruleset.lte("setting_max"),
                    },
                }}
            />
            <CFormGroup name="taxes">
                <CheckPicker
                    data={_.map(taxes?.data, (t: any) => ({ value: t?.id, label: `${t?.name} (${t?.rate}%)` }))}
                    value={_.map(form.getValues("taxes"), (t: any) => t?.id)}
                    onChange={tax =>
                        form.setValue(
                            "taxes",
                            _.intersectionWith(taxes?.data as any, tax, (a: any, b) => a.id === b),
                        )
                    }
                    onClean={() => form.setValue("taxes", [])}
                    placeholder={t("select", { name: t("tax") })}
                    style={{
                        width: 300,
                    }}
                />
            </CFormGroup>

            <div className={"tw-flex tw-gap-2 tw-mb-3 tw-items-start"}>
                <TextFormGroup name="gov_code" />
                {brandData.country === "UZ" && (
                    <IconButton onClick={() => selectGovCodeDrawer.setIsOpen(true)} icon={<CIcon path={iMore} />} />
                )}
            </div>
            {brandData.country === "UZ" && (
                <div className={"tw-flex tw-gap-2 tw-mb-3 tw-items-start"}>
                    <TextFormGroup name="properties.gov_package_code" label={t("gov_package_code")} />
                    <IconButton
                        onClick={() => selectGovPackageCodeDrawer.setIsOpen(true)}
                        icon={<CIcon path={iMore} />}
                    />
                </div>
            )}

            <TextFormGroup
                name="properties.measure"
                label={t("measure")}
                type="number"
                compProps={{
                    style: {
                        width: 300,
                    },
                }}
            />
            <RemoteSelectFormGroup
                name="properties.measure_unit_id"
                label={t("measure_unit")}
                searchLocal={true}
                remote={{
                    url: "unit",
                    filters: { categories: [["type", "!=", 4]] },
                    refineList: d => d?.map(translateUnit),
                }}
            />

            <ToggleFormGroup
                name="hidden"
                label={t("hide_on_terminal")}
                compProps={{
                    checkedChildren: <CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />,
                    unCheckedChildren: <CIcon path={iClose} style={{ marginBottom: "-3px" }} />,
                }}
            />
            <ToggleFormGroup
                name="ignore_service_charge"
                label={t("ignore_service_charge")}
                compProps={{
                    checkedChildren: <CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />,
                    unCheckedChildren: <CIcon path={iClose} style={{ marginBottom: "-3px" }} />,
                }}
            />
            <CheckboxFormGroup name={"giftable"} />
            <CheckboxFormGroup
                name={"inventory_behavior"}
                label={t("ignore_stock")}
                compProps={{ disabled: !isInventoryBehaviorChangeable }}
            />
            <CheckboxFormGroup
                name={"meta.hasPortion"}
                label={t("portion")}
                compProps={{
                    disabled: soldByWeight,
                }}
            />
            <CheckboxFormGroup name="discountable" reverseValue={true} label={t("not_allow_apply_discount")} />
            <CheckboxFormGroup
                name="sold_by_weight"
                label={t("sold_by_weight")}
                compProps={{
                    defaultChecked: !hasModifications,
                    disabled: hasModifications || !_.isNil(params.id),
                }}
                rules={{
                    deps: ["barcode"],
                }}
            />
            {hasExciseIntegration && (
                <CheckboxFormGroup
                    name={"properties.hasExcise"}
                    label={t("excise_branded_product")}
                    compProps={{
                        disabled: soldByWeight,
                    }}
                />
            )}
            <GoodsSettingsByVenue />
            <CreateCategoryModal {...modal} mainForm={form} />
            {selectGovCodeDrawer.isOpen && <SelectGovCodeDrawer {...selectGovCodeDrawer} govCode={"gov_code"} />}
            {selectGovPackageCodeDrawer.isOpen && (
                <SelectGovPackageCodeDrawer
                    {...selectGovPackageCodeDrawer}
                    govCode={"gov_code"}
                    govPackageCode={"properties.gov_package_code"}
                />
            )}
        </div>
    );
};

export default EditGeneralTab;
