import { t } from "lib/i18n";
import { ModifierGroupType } from "config/constants";
import { getParent, ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";

export const DishSchema: ValidationSchema = {
    name: ruleset.required,
    barcode: ruleset.barcodeWeight,
    price: ruleset.decimal2,
    low_stock: ruleset.required,
    max_age: ruleset.lte("setting_max"),
    contents: [
        {
            id: ruleset.required,
            gross: [ruleset.gte(0), ruleset.lte(Number.MAX_SAFE_INTEGER)],
            unit_weight: ruleset.required,
            cost_price: ruleset.required,
        },
    ],
    modificator_groups: [
        {
            modificators: [
                {
                    name: ruleset.required,
                    brutto: ruleset.gte(0),
                    price: ruleset.gte(0),
                    default_count: ruleset.combineIf(
                        (val, vals, par) => getParent(par)?.type === ModifierGroupType.MULTI,
                        [ruleset.gte("min_count", true), ruleset.lte("max_count", true)],
                    ),
                    min_count: ruleset.combineIf(
                        (val, vals, par) => getParent(par)?.type === ModifierGroupType.MULTI,
                        [
                            ruleset.gte(0),
                            ruleset.lte("max_count", true),
                            (value, values, parent) => {
                                const mg = getParent(parent) as IModifierGroup;
                                if (value > mg.max_select) {
                                    return t("validation_compare_value_less_or_equal", {
                                        compareValue: mg.max_select,
                                    });
                                }

                                const arr = mg.modificators ?? [];
                                const sum = arr.reduce((acc, item) => acc + (item.min_count ?? 0), 0);

                                return sum > mg.max_select ? t("sum_of_min_values_greater_than_max_select") : undefined;
                            },
                        ],
                    ),
                    max_count: [ruleset.gte(0), ruleset.gte("min_count", true)],
                },
            ],
        },
    ],

    meta: {
        availability: {
            schedules: {
                itemAvailability: [
                    {
                        days: ruleset.combineIf(
                            (value, values, parentData) => !!values.meta.availability.itemAvailability,
                            [ruleset.minLength(1)],
                        ),
                        hours: [
                            {
                                from: ruleset.combineIf(
                                    (value, values, parentData) => !!values.meta.availability.itemAvailability,
                                    [
                                        (value, values, parent, meta) => {
                                            const currentIndex = meta.currentIndex;
                                            if (!currentIndex) return;

                                            const prev = getParent(parent).hours[currentIndex - 1];

                                            return ruleset.gtTime(
                                                prev.to,
                                                t("start_date_can_not_be_great_before_end_date"),
                                            )(value, values, parent) as string;
                                        },
                                    ],
                                ),
                                to: ruleset.combineIf(
                                    (value, values, parentData) => !!values.meta.availability.itemAvailability,
                                    [ruleset.gtTime("from", t("start_date_can_not_be_great_end_date"), true)],
                                ),
                            },
                        ],
                    },
                ],
                itemVisibility: [
                    {
                        days: ruleset.combineIf(
                            (value, values, parentData) => !!values.meta.availability.itemVisibility,
                            [ruleset.minLength(1)],
                        ),
                        hours: [
                            {
                                from: ruleset.combineIf(
                                    (value, values, parentData) => !!values.meta.availability.itemVisibility,
                                    [
                                        (value, values, parent, meta) => {
                                            const currentIndex = meta.currentIndex;
                                            if (!currentIndex) return;

                                            const prev = getParent(parent).hours[currentIndex - 1];

                                            return ruleset.gtTime(
                                                prev.to,
                                                t("start_date_can_not_be_great_before_end_date"),
                                            )(value, values, parent) as string;
                                        },
                                    ],
                                ),
                                to: ruleset.combineIf(
                                    (value, values, parentData) => !!values.meta.availability.itemVisibility,
                                    [ruleset.gtTime("from", t("start_date_can_not_be_great_end_date"), true)],
                                ),
                            },
                        ],
                    },
                ],
            },
        },
    },
};
