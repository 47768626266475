import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { Modal } from "rsuite";
import CModal from "components/Drawers/CModal";
import TabsView from "components/TabsView";
import IntegrationHtmlViewer from "./pages/IntegrationHtmlViewer";
import { ASSETS_BASE_URL, getRemoteAssetUrl } from "config/main";
import CIcon from "components/CIcon";
import { iChat, iCheckBoxChecked, iClose, iPlus, iSpinner } from "assets/icons/Icons";
import { useIntercom } from "react-use-intercom";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import { format } from "date-fns";

interface IProps extends ICDrawerProps {
    type?: string;
    isLoading?: boolean;
    modulesPermission?: boolean;
    connectToModule: (d: any) => void;
    data?: IModule;
}

const formatDate = (date: string) => format(new Date(date), "dd.MM.yyyy");

const IntegrationModal: FCC<IProps> = ({ isLoading, modulesPermission, connectToModule, data, ...props }) => {
    const { show } = useIntercom();

    if (!data) return null;

    return (
        <CModal {...props} className="integration-modal" testId={`${data?.name}_modal`}>
            <div className="header">
                <div className="image">
                    <img
                        src={getRemoteAssetUrl(`integrations/${data?.name}.svg`)}
                        alt={data?.name}
                        className="img-fluid img-round"
                    />
                </div>
                <div className="text">
                    <span className="title">{t(data?.name) || data?.title}</span>
                    <span className="description">{t(`${data.name}_intro`)}</span>
                </div>
                <CIcon path={iClose} className="close" test-id="modal-close" onClick={props.onClose} />
            </div>
            <Modal.Body className="body">
                <div className="left-side">
                    <TabsView
                        tabs={[
                            {
                                label: t("description"),
                                Component: IntegrationHtmlViewer,
                                props: { html: t(`${data?.name}_module_desc`) },
                            },
                            {
                                label: t("getting_started"),
                                Component: IntegrationHtmlViewer,
                                props: { html: t(`module_getting_started`, { module_name: t(data?.name) }) },
                            },
                        ]}
                    />
                </div>
                <div className="right-side">
                    {data?.integration?.id ? (
                        <button className="connect-button connected" test-id={`module_${data?.name}_connected`}>
                            <CIcon path={iCheckBoxChecked} />
                            <TText tkey="connected" />
                        </button>
                    ) : modulesPermission ? (
                        <button
                            className="connect-button not-connected"
                            onClick={() => connectToModule(data)}
                            test-id={`module_${data?.name}_not_connected`}
                        >
                            <CIcon path={isLoading ? iSpinner : iPlus} spin={isLoading} />
                            <TText tkey="connect" />
                        </button>
                    ) : null}

                    <div className="help-section">
                        <div className="images">
                            <img src={`${ASSETS_BASE_URL}/img/avatar1.png`} alt="avatar1" />
                            <img src={`${ASSETS_BASE_URL}/img/avatar2.png`} alt="avatar2" />
                            <img src={`${ASSETS_BASE_URL}/img/avatar3.png`} alt="avatar3" />
                        </div>
                        <div className="d-grid">
                            <span className="title">
                                <TText tkey="have_questions" />
                            </span>
                            <span className="description">
                                <TText tkey="questions_desc" />
                            </span>
                        </div>
                        <div className="connection" onClick={() => show()}>
                            <CIcon path={iChat} />
                            <span className="link">
                                <TText tkey="get_in_touch" />
                            </span>
                        </div>
                    </div>

                    {data?.integration?.user ? (
                        <div className="category">
                            <span className="title">
                                <TText tkey="installed_by" /> {data?.integration?.user?.username}
                            </span>
                            <br />
                            <span className="title">{formatDate(data?.integration?.created_at)}</span>
                        </div>
                    ) : null}

                    {data?.categories?.length ? (
                        <div className="category">
                            <span className="title">
                                <TText tkey="category" />
                            </span>

                            <div className="cat">
                                {data?.categories?.map((cat: any, index: number) => {
                                    return (
                                        <div key={index} className="cat-item">
                                            <span>{cat?.name}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}
                </div>
            </Modal.Body>
        </CModal>
    );
};

export default IntegrationModal;
